/* eslint-disable */
import axios from 'axios';
import util from '@/utils/util';
import { DigitRoll } from '@/assets/js/charts/digitRoll.js';

const largeScreenFun = Object.create(null);

/**
 * @Description             格式化电子时间
 * @param obj               容器对象
 * @param nub               数字
 */
largeScreenFun.electronicNumber = (obj, nub) => {
    const dom = document.querySelector(obj);
    if(dom?.getAttribute('val')==nub){
        return;
    }else{
        dom?.setAttribute('val',nub);
    }
    if (!nub||nub<0 ) {
        nub = 0;
    }
    new DigitRoll({
        container: obj,
    }).roll(nub);
};

largeScreenFun.MP = ak => {
    return new Promise(function (resolve, reject) {
        window.init = function () {
            // eslint-disable-next-line no-undef
            resolve(BMap);
        };
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://api.map.baidu.com/api?v=3.0&ak=${ak}&s=1&callback=init`;
        script.onerror = reject;
        document.head.appendChild(script);
    });
};

largeScreenFun.BMPGL = ak => {
    return new Promise(function (resolve, reject) {
        window.init = function () {
        // eslint-disable-next-line
        resolve(BMapGL)
        };
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `http://api.map.baidu.com/api?v=1.0&type=webgl&ak=${ak}&callback=init`;
        script.onerror = reject;
        document.head.appendChild(script);
    });
};

/**
 * @Description             拉取场站
 */
largeScreenFun.getStationData = (tenant_id = '') => {
    const path = '/interfaceApi/report/largescreen3/czxx?tenant_id=' + tenant_id;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             生产发货统计数据
 * @param date              时间
 * @param stationId         场站ID
 * @param tenantId          租户ID
 */
// eslint-disable-next-line max-params
largeScreenFun.getShipmentStatistics = (date, stationId, tenantId) => {
    const path = '/interfaceApi/report/largescreen/getproddomesticsumkz/date'
        + `?date=${date}&station_id=${stationId}&tenantId=${tenantId}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             生产发货情况
 * @param date              时间
 * @param stationId         场站ID
 * @param tenantId          租户ID
 */
// eslint-disable-next-line max-params
largeScreenFun.getDeliveryStatus = (date, stationId, tenantId) => {
    const path = '/interfaceApi/report/largescreen/getproddomestictimeqkkz/date'
        + `?date=${date}&station_id=${stationId}&tenantId=${tenantId}`;
    return axios.get(path, { defEx: true });
};
/**
 * @Description             生产情况
 * @param date              时间
 * @param stationId         场站ID
 * @param tenantId          租户ID
 */
// eslint-disable-next-line max-params
largeScreenFun.getProductionStatus = (date, stationId, tenantId) => {
    const path = '/interfaceApi/report/largescreen/getsaledomestictimeqkkz/date'
        + `?date=${date}&station_id=${stationId}&tenantId=${tenantId}`;
    return axios.get(path, { defEx: true });
};
/**
 * @Description             生产发货统计数据-按时间
 * @param date              时间
 * @param stationId         场站ID
 */
largeScreenFun.getShipmentStatistics_time = (date, stationId) => {
    const path = `/interfaceApi/report/largescreen/getproddomestictimesum/date?date=${date}&station_id=${stationId}`;
    return axios.get(path, { defEx: true });
};

//
largeScreenFun.getProductionDeliveryCarStatis = (date, stationId, tenantId) => {
    const path = '/interfaceApi/report/largescreen/gettrunsumkz/date'
        + `?date=${date}&station_id=${stationId}&tenantId=${tenantId}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             生产发货列表
 * @param date              时间
 * @param stationId         场站ID
 * @param searchVal         查询条件
 * @param tenantId          租户ID
 */
// eslint-disable-next-line max-params
largeScreenFun.getProductionDeliveryTable = (date, stationId, tenantId, searchVal) => {
    const path = '/interfaceApi/report/largescreen/getproddomestic/date'
    + `?date=${date}&station_id=${stationId}&tenantId=${tenantId}&searchname=${searchVal}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             物资供应情况统计
 * @param date              时间
 * @param stationId         场站ID
 * @param tenantId          租户ID
 */
// eslint-disable-next-line max-params
largeScreenFun.getSupplyStatistics = (date, stationId, tenantId) => {
    const path = '/interfaceApi/report/largescreen/getsupplysum/date'
        + `?date=${date}&station_id=${stationId}&tenantId=${tenantId}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             物资供应情况统计-列表
 * @param date              时间
 * @param stationId         场站ID
 * @param tenantId          租户ID
 */
// eslint-disable-next-line max-params
largeScreenFun.getSupplyStatistics_rawMaterial = (date, stationId, tenantId) => {
    const path = '/interfaceApi/report/largescreen/getsupplyorder/date'
        + `?date=${date}&station_id=${stationId}&tenantId=${tenantId}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             物资供应情况统计-图表
 * @param date              时间
 * @param tenantId          租户ID
 */
largeScreenFun.getSupplyStatistics_chart = (typediccode, date, tenantId) => {
    const path = '/interfaceApi/report/largescreen/getsupplybytypename/date'
        + `?date=${date}&tenantId=${tenantId}&typediccode=${typediccode}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             供应物流车次
 * @param date              时间
 * @param stationId         场站ID
 * @param tenantId          租户ID
 * @param page              分页页码
 */
// eslint-disable-next-line max-params
largeScreenFun.getSupplyLogisticsStatistics = (date, tenantId,stationId, searchVal, page) => {
    // eslint-disable-next-line max-len
    let path = `/interfaceApi/report/largescreen/getsupplybalance/date?date=${date}&tenantId=${tenantId}&searchname=${searchVal}&pageindex=${page}&pagesize=6`;
    if(stationId){
        path+=`&stationId=${stationId}`;
    }
    return axios.get(path, { defEx: true });
};

/**
 * @Description             料仓监控获取料仓信息
 * @param station_id        场站ID
 * @param tenant_id         租户id
 */
largeScreenFun.getSiloData = (station_id, tenant_id = '') => {
    const path = `/interfaceApi/report/largescreen/monitor/${station_id}?tenant_id=${tenant_id}`;
    return axios.get(path, { defEx: true });
};


/**
 * @Description             获取当天有任务的工地
 * @param date              时间
 * @param station_id        场站ID
 */
largeScreenFun.getConstructionSiteData = (date, tenantId, station_id) => {
    const path = `/interfaceApi/report/largescreen/get_project_status?date=${date}&stationCode=${station_id}&tenant_id=${tenantId}`;
    return axios.get(path, { defEx: true });
};


/**
 * @Description             获取当天有任务的车辆
 * @param station_id        场站ID
 * @param searchVal         搜索条件
 */
largeScreenFun.getCarLocationData = (tenantId,stationId, searchVal = '') => {
    let path = `/interfaceApi/report/largescreen/get_location?tenant_id=${tenantId}&stationId=${stationId}&type=0&keywords=${searchVal}&task_number=`;
    return axios.get(path, { defEx: true });
};
// /**
//  * @Description             获取场站位置
//  * @param station_id        场站ID
//  */
// largeScreenFun.getStationLocation = station_id => {
//     const path = `/interfaceApi/report/largescreen/getstationlat/station?station_id=${station_id}`;
//     return axios.get(path, { defEx: true });
// };

/**
 * @Description             获取生产发货运输位置信息
 * @param date              时间
 * @param station_id        场站ID
 * @param tenantId          租户ID
 * @param searchVal         查询条件
 */
// eslint-disable-next-line max-params
largeScreenFun.getSiteLocation = (date, station_id, tenantId, searchVal = '') => {
    // eslint-disable-next-line max-len
    const path = `/interfaceApi/report/largescreen/getproddomesticlat/date?date=${date}&station_id=${station_id}&tenantId=${tenantId}&searchname=${searchVal}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             获取场站位置
 * @param station_id        场站ID
 */
largeScreenFun.getExceptionsGroup = tenantId => {
    const path = `/interfaceApi/logistics/car_manager/get_exceptions_group?tenantId=${tenantId}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             获取场站位置
 * @param station_id        场站ID
 */
// eslint-disable-next-line max-params
largeScreenFun.getExceptions = (searchVal = '', tenantId, pageindex, type, pagesize = 10) => {
    // eslint-disable-next-line max-len
    const path = `/interfaceApi/logistics/car_manager/get_exceptions?keywords=${searchVal}&tenantId=${tenantId}&pageindex=${pageindex}&pagesize=${pagesize}&type=${type}`;
    return axios.get(path, { defEx: true });
};
/**
 * @Description             获取车辆信息
 * @param carName           车号
 */
largeScreenFun.getVehicleInfo = (date, carName,tenantId) => {
    const path = `/interfaceApi/report/largescreen/getproddomesticcar/date?date=${date}&plate_number=${carName}&tenantId=${tenantId}`;
    return axios.get(path, { defEx: true });
};

largeScreenFun.getSupplyVehicleInfo = (date, carName,station_id,tenantId) => {
    const path = `/interfaceApi/report/largescreen/get_supply_car?date=${date}&plate_number=${carName}&station_id=${station_id}&tenantId=${tenantId}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             获取工地信息
 * @param project_code      任务编号
 */
largeScreenFun.getConstructionSiteInfo = (date, project_code,station_id,tenantId) => {
    const path = `/interfaceApi/report/largescreen/getproddomesticpub/date?date=${date}&project_code=${project_code}&station_id=${station_id}&tenantId=${tenantId}`;
    return axios.get(path, { defEx: true });
};

// 供应物流
/**
 * @Description             获取货源地地理位置信息
 * @param date              时间
 * @param station_id        场站ID
 */
// eslint-disable-next-line max-params
largeScreenFun.getSupplySiteLocation = (date, station_id, searchVal = '', tenantId) => {
    const path = '/interfaceApi/report/largescreen/getproddomesticlat/date'
    + `?date=${date}&station_id=${station_id}&searchname=${searchVal}&tenantId=${tenantId}`;
    return axios.get(path, { defEx: true });
};
/**
 * @Description             获取采购车辆位置
 * @param date              时间
 * @param station_id        场站ID
 */
// eslint-disable-next-line max-params
largeScreenFun.getSupplyCarLocation = (date, station_id, searchVal = '', tenantId) => {
    const path = '/interfaceApi/report/largescreen/gettranlocationdomestic/date'
        + `?date=${date}&station_id=${station_id}&searchname=${searchVal}&tenantId=${tenantId}`;
    return axios.get(path, { defEx: true });
};


/**
 * 获取生产发货信息
 * @param date
 * @param station_id
 * @param searchVal
 * @param tenantId
 * @returns {Promise<AxiosResponse<any>>}
 */
largeScreenFun.getProductionStation = (date, tenantId) => {
    const path = '/interfaceApi/report/largescreen/get_prodd_omestic_station/date'
        + `?date=${date}&tenantId=${tenantId}`;
    return axios.get(path, { defEx: true });
};
/**
 * 获取生产车辆统计
 * @param date
 * @param tenantId
 * @returns {Promise<AxiosResponse<any>>}
 */
largeScreenFun.getCarStatis = (date, tenantId) => {
    const path = '/interfaceApi/report/largescreen/get_carrun_sum/date'
        + `?date=${date}&tenantId=${tenantId}`;
    return axios.get(path, { defEx: true });
};
/**
 * 获取料仓信息
 * @param date
 * @param tenantId
 * @returns {Promise<AxiosResponse<any>>}
 */
largeScreenFun.getTypeStock = (tenantId,station_id) => {
    let path = '/interfaceApi/report/largescreen/getstockmonitor'
        + `?tenantId=${tenantId}`;
    if(station_id){
        path += `&station_id=${station_id}`;
    }
    return axios.get(path, { defEx: true });
};

/**
 * @Description             获取运输车辆位置列表 新
 * @param date
 * @param tenantId
 * @param station_id
 * @param searchname
 */
largeScreenFun.getTranCarLocation = (date, tenantId, station_id,searchname = '') => {
    const path = `/interfaceApi/report/largescreen/gettranlocation/date?date=${date}&stationId=${station_id}&&searchname=${searchname}&tenantId=${tenantId}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             获取采购货源地地理位置信息 新
 * @param station_id        场站ID
 * @param searchVal         搜索条件
 * @param searchVal         搜索条件
 * @param searchVal         搜索条件
 */
largeScreenFun.getSupplyLocation = (date, tenantId, station_id,searchname = '') => {
    const path = `/interfaceApi/report/largescreen/getsupplylocation/date?date=${date}&stationId=${station_id}&&searchname=${searchname}&tenantId=${tenantId}`;
    return axios.get(path, { defEx: true });
};

/**
 * @Description             获取场站位置信息 新
 * @param station_id        场站ID
 * @param searchVal         搜索条件
 */
largeScreenFun.getStationLocation = (tenantId) => {
    const path = `/interfaceApi/report/largescreen/getstationlatall/station?tenantId=${tenantId}`;
    return axios.get(path, { defEx: true });
};



export default largeScreenFun;
